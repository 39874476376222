import { filter, fromEvent, skip } from 'rxjs'

import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Directive({
  selector: '[appClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective implements OnInit {
  @Output() appClickOutside = new EventEmitter<Event>()

  @Input() shoulSkipFirst = true

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const pipes = this.shoulSkipFirst ? [skip(1)] : []

    fromEvent<MouseEvent>(document, 'click')
      .pipe(
        ...(<[]>pipes),
        filter((event) => this.isOutside(event)),
      )
      .subscribe(() => {
        this.appClickOutside.emit()
      })
  }

  private isOutside(event: MouseEvent): boolean {
    const clickedInside = this.elementRef.nativeElement.contains(event.target)

    return !clickedInside
  }
}
