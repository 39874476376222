<div class="block" appClickOutside (clickOutside)="onCloseA11y()">
  <div class="block__inner">
    <h5>Налаштувати вигляд</h5>
    <div class="block__control-group flex flex-col">
      <div class="block__control">
        <p class="h8 mb-1">Розмір тексту</p>
        <div class="d-flex">
          <button
            [attr.aria-label]="'Зменшити розмір тексту'"
            [disabled]="accessibilityService.fontSizeLevel === accessibilityService.minFontSizeLevel"
            (click)="accessibilityService.decrementFontSizeLevel()"
          >
            <div class="icon size-minus"></div>
          </button>
          <button
            [attr.aria-label]="'Збільшити розмір тексту'"
            [disabled]="accessibilityService.fontSizeLevel === accessibilityService.maxFontSizeLevel"
            (click)="accessibilityService.incrementFontSizeLevel()"
          >
            <div class="icon size-plus"></div>
          </button>
        </div>
      </div>
      <div class="block__control">
        <p class="h8 mb-1">Відступи між буквами</p>
        <div class="d-flex">
          <button
            [attr.aria-label]="'Зменшити відступи між буквами'"
            [disabled]="accessibilityService.letterSpacingLevel === accessibilityService.minLetterSpacingLevel"
            (click)="accessibilityService.decrementLetterSpacingLevel()"
          >
            <div class="icon size-minus"></div>
          </button>
          <button
            [attr.aria-label]="'Збільшити відступи між буквами'"
            [disabled]="accessibilityService.letterSpacingLevel === accessibilityService.maxLetterSpacingLevel"
            (click)="accessibilityService.incrementLetterSpacingLevel()"
          >
            <div class="icon size-plus"></div>
          </button>
        </div>
      </div>
      <div class="block__control">
        <p class="h8 mb-1">Колір</p>
        <div class="d-flex">
          <button
            [attr.aria-label]="'Звичайний режим'"
            [attr.aria-pressed]="accessibilityService.contrastMode === ContrastMode.Color"
            (click)="accessibilityService.setContrast(ContrastMode.Color)"
          >
            <div class="icon mode-color"></div>
            Контраст
          </button>
          <button
            [attr.aria-label]="'Режим контрастності'"
            [attr.aria-pressed]="accessibilityService.contrastMode === ContrastMode.Contrast"
            (click)="accessibilityService.setContrast(ContrastMode.Contrast)"
          >
            <div class="icon mode-contrast"></div>
            ЧБ
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="block__bottom">
    <button
      class="btn block__bottom-reset"
      [disabled]="
        accessibilityService.contrastMode === ContrastMode.Color &&
        accessibilityService.fontSizeLevel === accessibilityService.minFontSizeLevel &&
        accessibilityService.letterSpacingLevel === accessibilityService.minLetterSpacingLevel
      "
      (click)="onReset()"
    >
      Скинути
    </button>
  </div>
</div>
