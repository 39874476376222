<div
  *ngIf="isVisible"
  #sub
  [@slideInOut]
  class="sub bg-background-main pb-56 absolute top-82 left-0 w-full z-10"
  appClickOutside
  (appClickOutside)="hide()"
  role="dialog"
  aria-label="Меню навігації"
>
  <div class="wrapper">
    <div #emptyEl [tabIndex]="1" class="absolute opacity-0"></div>
    <div class="flex flex-wrap gap-16 mt-56">
      <a
        *ngFor="let link of items; let i = index"
        #linkItem
        class="sub__item"
        [routerLink]="link.url"
        (click)="clickOutside()"
        [attr.aria-label]="link.label"
        [tabindex]="0"
        (keydown.enter)="navigate(link.url); clickOutside()"
        (keydown.space)="navigate(link.url); clickOutside()"
      >
        {{ link.label }}
      </a>
    </div>
  </div>
</div>
