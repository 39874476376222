<ng-container *ngIf="subNavigationItems$ | async as subNavigationItems">
  <app-sub-navigation
    *ngIf="subNavigationItems?.length"
    [items]="subNavigationItems"
    (closeSubNavigationEvent)="closeSubNavigation()"
    aria-label="Підменю навігації"
  />
</ng-container>

<div class="navigation flex items-center gap-24 flex-wrap">
  <ng-container *ngFor="let item of items; let i = index">
    <ng-container *ngIf="item?.items?.length; else link">
      <h6
        class="navigation__item cursor-pointer"
        (click)="openItems(item)"
        [tabindex]="0"
        role="button"
        [attr.aria-label]="'Розгорнути меню ' + item.label"
        (keydown.enter)="openItems(item)"
        (keydown.space)="openItems(item)"
      >
        <span class="whitespace-nowrap">
          {{ item.label }}
        </span>
        <mat-icon [svgIcon]="svgIcons.NAV_ARROW" />
      </h6>
    </ng-container>
    <ng-template #link>
      <a class="navigation__item" [routerLink]="item.url" [attr.aria-label]="item.label">
        <h6 class="whitespace-nowrap">
          {{ item.label }}
        </h6>
      </a>
    </ng-template>
  </ng-container>

  <div class="navigation__burger flex items-center gap-4 ml-auto">
    <mat-icon
      [svgIcon]="svgIcons.BURGER"
      (click)="openMobile()"
      tabindex="0"
      role="button"
      aria-label="Відкрити мобільну навігацію"
      (keydown.enter)="openMobile()"
      (keydown.space)="openMobile()"
    />
  </div>

  <div *ngIf="isShowMobileNavigation$ | async" class="navigation__mobile fixed w-full top-0 left-0" aria-label="Мобільна навігація">
    <app-mobile-navigation [items]="items" [isPortal]="isPortal" (closeEvent)="closeMobile()" />
  </div>
</div>

<mat-menu #menu="matMenu" aria-label="Меню профілю">
  <a *ngIf="!isPortal" mat-menu-item (click)="menuEvent('profile')" class="menu__item" aria-label="Mій профіль">Mій профіль</a>
  <a *ngIf="isPortal" mat-menu-item [routerLink]="navigation.Login" class="menu__item" aria-label="Увійти до кабінету"
    >Увійти до кабінету</a
  >
  <ng-container *ngFor="let item of items">
    <a mat-menu-item [routerLink]="item.url" class="menu__item" [attr.aria-label]="item.label">{{ item.label }}</a>
  </ng-container>
  <button *ngIf="!isPortal" mat-menu-item (click)="menuEvent('logout')" class="menu__item" aria-label="Вийти">Вийти</button>
</mat-menu>
