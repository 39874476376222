import { animate, style, transition, trigger } from '@angular/animations'

export const slideInOutAnimation = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', opacity: 0 }),
    animate('200ms ease-in', style({ transform: 'translateY(0%)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0%)', opacity: 1 }),
    animate('200ms ease-in', style({ transform: 'translateY(-100%)', opacity: 0 })),
  ]),
])
