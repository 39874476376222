import { timer } from 'rxjs'
import { NavigationItem } from 'src/app/common/header/components/navigation/shared/types'

import { A11yModule } from '@angular/cdk/a11y'
import { NgFor, NgIf } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { Router, RouterLink } from '@angular/router'

import { slideInOutAnimation } from 'src/app/core/animations/slide-in-out.snimation'
import { ClickOutsideDirective } from 'src/app/core/directives/click-outside.directive'

@Component({
  selector: 'app-sub-navigation',
  standalone: true,
  imports: [RouterLink, NgFor, ClickOutsideDirective, NgIf, A11yModule],
  templateUrl: './sub-navigation.component.html',
  styleUrl: './sub-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation],
})
export class SubNavigationComponent implements AfterViewInit {
  @Input() items: NavigationItem[] = []

  @Output() closeSubNavigationEvent: EventEmitter<void> = new EventEmitter<void>()

  @ViewChildren('linkItem') linkItems!: QueryList<ElementRef>

  @ViewChild('emptyEl') emptyEl!: ElementRef<HTMLDivElement>

  isVisible = true

  constructor(private router: Router) {}

  @HostListener('document:keydown.escape', ['$event'])
  private handleEscape(): void {
    this.hide()
  }

  ngAfterViewInit(): void {
    if (this.linkItems.first) {
      this.emptyEl.nativeElement.focus()
    }
  }

  hide(): void {
    this.isVisible = !this.isVisible
    timer(150).subscribe(() => {
      this.clickOutside()
    })
  }

  clickOutside(): void {
    this.closeSubNavigationEvent.emit()
  }

  navigate(url: string): void {
    this.router.navigate([url])
  }
}
