import { NavigationItem } from '@app/common/header/components/navigation/shared/types'
import { SvgIcon } from '@app/shared/svg-icons/svg-icons.enum'
import { SvgIconsModule } from '@app/shared/svg-icons/svg-icons.module'
import { BtnComponent } from '@app/ui-kit/component/btn/btn.component'
import { timer } from 'rxjs'

import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'

import { slideInOutAnimation } from 'src/app/core/animations/slide-in-out.snimation'

@Component({
  selector: 'app-mobile-navigation',
  standalone: true,
  imports: [MatExpansionModule, NgFor, MatIconModule, SvgIconsModule, NgIf, RouterLink, BtnComponent],
  templateUrl: './mobile-navigation.component.html',
  styleUrl: './mobile-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation],
})
export class MobileNavigationComponent {
  @Input() items: NavigationItem[] = []
  @Input() isPortal!: boolean

  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>()

  svgIcons = SvgIcon

  isVisible = true

  constructor(private router: Router) {}

  close(): void {
    this.isVisible = false
    timer(150).subscribe(() => {
      this.closeEvent.emit()
    })
  }

  goToLogin(): void {
    this.router.navigate(['/login'])
  }
}
